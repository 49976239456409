
<template>
<div>
  <div class='hole' style="cursor: pointer" @click="show();">
    <i></i>
    <i></i>
    <i></i>
    <i></i>
    <i></i>
    <i></i>
    <i></i>
    <i></i>
    <i></i>
    <i></i>
  </div>
  <audio autoplay loop>
    <source src="/sounds/ncs-spectre.mp3" type="audio/mp3">
  </audio>
</div>
</template>
  
<script>
export default {
  name: 'App',
  data: () => ({
    audio: null,
  }),
  components: {

  },
  created: () => {

  },
  mounted() {
    this.audio = document.querySelector('audio');
    this.audio.volume = 0.6;
  },
  methods: {
    show() {
      if (this.audio.paused) {
        this.audio.play();
      } else {
        this.audio.pause();
      }
    }
  }
}
</script>

<style>
html, body {
  overflow: hidden;
  background: #111;
}

.hole {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
}

i {
  display: block;
  position: absolute;
  width: 140px;
  height: 140px;
  left: -70px;
  top: -70px;
  border-radius: 140px;
  opacity: 0;
  animation-name: scale;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

i:nth-child(1) {
  animation-delay: 0.3s;
}

i:nth-child(2) {
  animation-delay: 0.6s;
}

i:nth-child(3) {
  animation-delay: 0.9s;
}

i:nth-child(4) {
  animation-delay: 1.2s;
}

i:nth-child(5) {
  animation-delay: 1.5s;
}

i:nth-child(6) {
  animation-delay: 1.8s;
}

i:nth-child(7) {
  animation-delay: 2.1s;
}

i:nth-child(8) {
  animation-delay: 2.4s;
}

i:nth-child(9) {
  animation-delay: 2.7s;
}

i:nth-child(10) {
  animation-delay: 3s;
}

@keyframes scale {
  0% {
    transform: scale(2);
    opacity: 0;
    box-shadow: 0px 0px 50px rgba(255, 255, 255, 0.5);
  }
  50% {
    transform: scale(1) translate(0px, -5px);
    opacity: 1;
    box-shadow: 0px 8px 20px rgba(255, 255, 255, 0.5);
  }
  100% {
    transform: scale(0.1) translate(0px, 5px);
    opacity: 0;
    box-shadow: 0px 10px 20px rgba(255, 255, 255, 0);
  }
}
</style>